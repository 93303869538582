import React from 'react'
import AppRouter from '../router/AppRouter'
import Authentication from './Authentication'

const App = () => (
    <>
        {/* <Authentication> */}
            <AppRouter />
        {/* </Authentication> */}
    </>
    )

export default App
