import React from 'react'
import '../../styles/ErrorMessage.css'

const ErrorMessage = () => (
    <div className="ErrorMessage">
        <h2>something is fishy...</h2>
    </div>
)

export default ErrorMessage
